import cn from "classnames";
import { ReactElement, ReactNode } from "react";

import Heading, { HeadingLevel } from "../../atoms/heading/Heading";

interface PageTitleProps {
  title: ReactNode;
  subtitle?: ReactNode;
  className?: string;
  children?: ReactNode;
  headingLevel?: HeadingLevel;
  adoptionClassName?: string;
}
const PageTitle = ({
  title,
  subtitle,
  children,
  headingLevel = 1,
  adoptionClassName,
}: PageTitleProps): ReactElement => {
  return (
    <header className={cn("ml-page-title", adoptionClassName)}>
      <Heading level={headingLevel} adoptionClassName="page-title">
        {title}
      </Heading>
      {subtitle && <p className="page-subtitle">{subtitle}</p>}
      {children}
    </header>
  );
};

export default PageTitle;
